import {useState} from 'react'
import Style from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuestionCircle,
  faCheckCircle,
  faExclamationTriangle,
  faMinusCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import UseCoinsStatusContext from '../../contexts/coins_status/UseCoinsStatusContext'

const StatusIcon = ({status}) => {
  let icon;
  switch(status) {
    case 'ok':
      icon = faCheckCircle;
      break;
    case 'degraded':
      icon = faExclamationTriangle;
      break;
    case 'broken':
      icon = faMinusCircle;
      break;
    case 'no_service':
      icon = faTimesCircle;
      break;
    default:
      icon = faQuestionCircle;
      break;
  }
  return (
    <FontAwesomeIcon icon={icon} />
  )
}
const Legend = ({status, label}) => {
  return (
    <Style.LegendItem>
      <Style.StatusLabelIcon className={`icon ${status}`}>
        <StatusIcon status={status} />
      </Style.StatusLabelIcon>
      <label>{label}</label>
    </Style.LegendItem>
  )
}
const CoinStatusLabel = ({ status, details }) => {
  return (
    <Style.StatusLabelContent>
      <Style.StatusLabelIcon className={status}>
        <StatusIcon status={status} />
        {details && (
          <div className='tooltip'>{details}</div>
        )}
      </Style.StatusLabelIcon>
    </Style.StatusLabelContent>
  )
}

const TableRow = ({name, trading, deposits, withdrawals}) => {
  return (
    <Style.TableRow>
      <Style.CoinName>{ name }</Style.CoinName>
      <CoinStatusLabel {...trading} />
      <CoinStatusLabel {...deposits} />
      <CoinStatusLabel {...withdrawals} />
    </Style.TableRow>
  )
}

const Dashboard = () => {
  const [filter, setFilter] = useState('');
  let { coinsStatus } = UseCoinsStatusContext()

  const filteredResultList = () => {
    if (!filter) {
        return coinsStatus
    }
    const filterLowerCase = filter.toLocaleLowerCase()
    return coinsStatus.filter(entry => entry.name.toLowerCase().includes(filterLowerCase))
  }
  return (
    <Style.Container>
      <Style.Legends>
        <Legend status={'ok'} label={'Up'} />
        <Legend status={'degraded'} label={'Slow'} />
        <Legend status={'broken'} label={'Down'} />
        <Legend status={'no_service'} label={'Not Supported'} />
        <Style.Clear />
      </Style.Legends>
      <Style.Content>
        <Style.SearchBox>
          <span>FILTER BY SYMBOL: </span><input type={'text'} value={filter} onChange={e => setFilter(e.target.value)} />
        </Style.SearchBox>
        <Style.CoinStatusesTable>
          <Style.TableHeader>
            <Style.TableColumnName>Currency</Style.TableColumnName>
            <Style.TableColumnName>Trading</Style.TableColumnName>
            <Style.TableColumnName>Deposits</Style.TableColumnName>
            <Style.TableColumnName>Withdrawals</Style.TableColumnName>
          </Style.TableHeader>
          <Style.TableContent>
            {filteredResultList().map(function(data){
              return <TableRow {...data} key={data.name} />
            })}
          </Style.TableContent>
        </Style.CoinStatusesTable>
      </Style.Content>
    </Style.Container>
  )
}

export default Dashboard;
