import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import Style from './style'

const LoadingPanel = () => {
  return (
    <Style.Container>
      <Style.Icon>
        <FontAwesomeIcon icon={faSpinner} />
      </Style.Icon>
    </Style.Container>
  )
}

export default LoadingPanel;
