import styled from 'styled-components';

const Style = {
  Container: styled.div`
    background: black;
    color: white;
    margin-bottom: 45px;
  `,
  Content: styled.div`
    padding: 15px 0;
    max-width: 760px;
    margin: 0 20px;
    font-size: 20px;
    @media (min-width: 600px) {
      margin: 0 auto;
    }
    a {
      color: white;
      text-decoration: none;
    }
    
    .return-to-try-anchor {
      float: right;
      font-size: 15px;
      
      svg {
        margin-right: 5px;
      }
    }
  `,

  ActionButtonContainer: styled.div`
    float: right;
    padding: 10px;
  `,

  Clear: styled.div`
    clear: both;
  `
};

export default Style;
