import styled from 'styled-components';

const Style = {
  Container: styled.div`
    max-width: 760px;
    padding: 0 0 50px;
    margin: 0 auto;
    border: none;
    text-align: center;
  `,
  Header: styled.div`
    margin-bottom: 20px;
  `,
  HeaderTitle: styled.h2`
    color: white;
    text-align: center;
    padding: 12px;
    border-radius: 5px;
    font-size: 1.3em;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    background: black;
    
    a {
      color: white;
    }
  `,
  HeaderDescription: styled.p`
    
  `,
  Content: styled.div`
    
  `,
  SearchBox: styled.div`
    text-align: left;
    margin-bottom: 20px;
    
    span {
      font-weight: bold;
      font-size: 16px;
    }
    
    input {
      height: 25px;
      width: 200px;
    }
  `,
  CoinStatusesList: styled.div`
  `,
  CoinStatusesTable: styled.div`
  `,
  TableHeader: styled.div`
    display: flex;
    margin-bottom: 20px;
  `,
  TableColumnName: styled.div`
    font-weight: bold;
    flex-direction: column;
    text-align: center;
    width: 25%;
   
    &:first-child {
      text-align: left;
    }
  `,
  TableContent: styled.div``,

  TableRow: styled.div`
    display: flex;

    background: white;
    padding: 16px !important;
    border-left: 1px solid #48CBA5;
    margin-bottom: 15px !important;
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%) !important;
}
  `,
  CoinName: styled.div`
    flex-direction: column;
    width: 25%;
    text-align: left;
    font-weight: bold;
    margin-top: 5px;
  `,
  StatusLabelContent: styled.div`
    flex-direction: column;
    width: 25%;
    text-align: center;
  `,
  StatusLabelIcon: styled.span`
    display: inline-block;
    font-size: 25px;
    &.ok {
      color: green;
    }
    
    &.degraded {
      color: #fbb034;
    }
    
    &.broken {
      color: red;
    }
    
    &.no_service {
      color: black;
    }
    
    .tooltip {
      display: none;
    }
    
    &:hover {
      .tooltip {
        border: 1px solid black;
        border-radius: 5px;
        padding: 5px 10px;
        display: block;
        position: absolute;
        color: white;
        background-color: black;
      }
    }
  `,
  Legends: styled.div`
    clear: both;
    margin-top: 20px;
    text-align: left;
    border-bottom: 15px solid white;
    margin-bottom: 20px;
  `,
  LegendItem: styled.div`
    padding-bottom: 15px;
    float: left;
    margin-right: 20px;
    
    label {
      font-weight: bold;
      font-size: 13px;
      text-transform: capitalize; 
      padding-left: 5px;
      display: inline-block;
      margin-top: 3px;
    }
    
    .icon {
      float: left;
      font-size: 18px;
    }
    
    @media (min-width: 600px) {
      margin-right: 35px;
     
      label {
        font-size: 16px;
        margin-top: 5px;
        padding-left: 10px;
      }
      
      .icon {
        font-size: 25px;
      }
    }
  `,
  Clear: styled.div`
    clear: both;
  `
};

export default Style;
