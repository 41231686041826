import React, { Suspense } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'

import Header from './components/molecules/Header'
import Footer from './components/molecules/Footer'
import Dashboard from './components/pages/Dashboard'
import ErrorPage from './components/pages/ErrorPage'

import LoadingPanel from './components/molecules/LoadingPanel'
import AppStyle from './App.style'
import CoinsStatusProvider from "./components/contexts/coins_status/CoinsStatusProvider";

const App = () => {
  return (
    <CoinsStatusProvider>
      <HttpsRedirect>
        <Router>
          <AppStyle.Container>
            <Header />
            <AppStyle.MainContent>
              <Suspense fallback={<LoadingPanel />}>
                <Switch>
                  <Route exact path='/' component={Dashboard} />
                  <Route component={ErrorPage} />
                </Switch>
              </Suspense>
            </AppStyle.MainContent>
            <Footer />
          </AppStyle.Container>
        </Router>
      </HttpsRedirect>
    </CoinsStatusProvider>
  );
}

export default App;
