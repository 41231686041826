import { useState, useEffect } from 'react'
import { CoinsStatusContext } from './CoinsStatusContext'
import axios from 'axios'

const CoinsStatusProvider = ({ children }) => {
  const [coinsStatus, setCoinsStatus] = useState([]);

  useEffect(() => {
    axios.get('/api/coins/statuses').then(res => {
      const data = res.data
      let resultList = []
      if (data.statuses) {
        Object.keys(data.statuses).forEach(key => {
          resultList.push({
            name: key,
            trading: data.statuses[key].trading,
            deposits: data.statuses[key].deposits,
            withdrawals: data.statuses[key].withdrawals,
          })
        })
        setCoinsStatus(resultList);
      }
    })
  }, []);

  return (
    <CoinsStatusContext.Provider value={{ coinsStatus, setCoinsStatus }}>
      {children}
    </CoinsStatusContext.Provider>
  )
}

export default CoinsStatusProvider
