import styled from 'styled-components';

const Style = {
  Container: styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  `,
  Icon: styled.div`
    text-align: center;
    font-size: 200px;
  `
};

export default Style;
