import styled from 'styled-components';

const AppStyle = {
  Container: styled.div`
    font-family: 'Red Hat Text', sans-serif;
    color: #333;
    background: #f6f7f9;
  `,
  MainContent: styled.div`
   max-width: 760px;
   margin-left: 20px;
   margin-right: 20px;
   
   @media (min-width: 600px) {
     margin-left: auto;
     margin-right: auto;
   }
  `
};

export default AppStyle;
