import React from 'react'
import Style from './style'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
const Header = () => {
  return (
    <Style.Container>
      <Style.Content>
        <a href='/'>Anchor | Crypto Service Status</a>
        <a href='https://tryanchor.com' className={'return-to-try-anchor'}>
          <FontAwesomeIcon icon={faChevronLeft} /> Return to tryanchor.com
        </a>
      </Style.Content>
    </Style.Container>
  )
}

export default Header;
